/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
require("@rails/ujs").start();

import '../src/jquery-migrate.min';
import '../src/jquery.tokeninput';
import '../src/jquery-ui-1.8.17.custom.min';
import '../src/jquery-ui-timepicker-addon';
import '../src/jquery.fancybox.js';
import '../src/messages';
import '../src/functions';
import '../src/forms';
import '../src/admin/events';
import '../src/darkroast_form';
import '../src/darkroast_daterange';
import '../src/admin/vendors';
import '../src/home';
import '../src/slimbox2';
import '../src/bootstrap.min.js';
import '../src/ie10-viewport-bug-workaround.js';
import '../src/pages.js';

