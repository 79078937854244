# contains a bunch of global functions, possibly these should be put into an object
window.toggle_inline = (dom_item) ->
  toggle_base_id = dom_item.data 'toggle'
  $("#{toggle_base_id}#{suffix}").toggle() for suffix in [ '_display' , '_edit' ]
  false

window.reset_form = (form_id) -> $(form_id)[0].reset()

window.capitalize = (string) ->
  string.charAt(0).toUpperCase() + string.slice 1

window.show_errors = (errors) ->
  messages = for field, messages of errors
    friendly_field = (capitalize piece for piece in field.split '_' ).join ' '
    friendly_messages = (capitalize msg for msg in messages ).join "\n"
    "#{friendly_field}: #{friendly_messages}"

  alert( messages.join("\n") )
